import { jsx } from "react/jsx-runtime";
import { lazy, useMemo } from "react";
const LazyCard = lazy(
  () => import("./card-koVvYmDz.js").then((module) => ({
    default: module.Card
  }))
);
function Card({ id: _id, name: _name, ...props }) {
  return /* @__PURE__ */ jsx(LazyCard, { ...props });
}
const useCardContent = () => {
  return useMemo(
    () => ({
      card: {
        component: Card
      }
    }),
    []
  );
};
export {
  LazyCard as Card,
  useCardContent
};
